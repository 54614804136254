$global-font-size: 20px;
$global-line-height: 1.6rem;

// Color of the secondary text
$global-font-secondary-color: #7a7a86;
$global-font-secondary-color-dark: #8b8b8e;

// Color of the link
$global-link-color: #343027;
$global-link-color-dark: #bfbfc8;

// Height of the header
$header-height: 3rem;
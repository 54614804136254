html {
    overflow-y: scroll;
}

h1 > a.header-mark,
h2 > a.header-mark,
h3 > a.header-mark,
h4 > a.header-mark,
h5 > a.header-mark,
h5 > a.header-mark {
    display: none;
}

h1:hover > a.header-mark,
h2:hover > a.header-mark,
h3:hover > a.header-mark,
h4:hover > a.header-mark,
h5:hover > a.header-mark,
h5:hover > a.header-mark {
    display: inline;
}

header .logo {
    padding-right: 0.5rem;
    min-height: 1.2em;
    height: 1.2em;
}

footer {
    height: auto;
    padding: 0;
}

div.footer-line {
    padding: 0.5em 0;
}

div.footer-line a {
    color: $global-link-color;
}

div.footer-line a:hover {
    color: $global-link-hover-color;
}

[theme=dark] div.footer-line a {
    color: $global-link-color-dark;
}

[theme=dark] div.footer-line a:hover {
    color: $global-link-hover-color-dark;
}

div.footer-line > i.fa-copyright {
    font-size: 0.8rem;
    padding-right: 0.25em;
}

div.footer-line span.license {
    display: block;
    font-size: 0.7rem;
    line-height: 1.1em;
}

div.footer-line span.google-analytics-tracking-info {
    display: block;
    font-size: 0.7rem;
    color: $global-font-color;
    line-height: 1.1em;
}

[theme=dark] div.footer-line span.google-analytics-tracking-info {
    color: $global-font-color-dark;
}

div.img-float-right {
    float: right;
}


[theme=dark] .header-title .logo-light {
    display: none !important;
}

[theme=dark] .header-title .logo-dark {
    display: inline !important;
}

[theme=light] .header-title .logo-light {
    display: inline !important;
}

[theme=light] .header-title .logo-dark {
    display: none !important;
}



h1.single-title,
h2.single-title,
h3.single-title,
h4.single-title,
h5.single-title,
h6.single-title {
    text-align: left !important;
    line-height: 1.2;
}

div.expand span.instructions {
    font-size: 66%;
    opacity: 0.4;
}

div.star-ranking {
    margin: 1em 0;
}

div.star-ranking i.fa {
    font-size: 150%;
}

div.star-ranking span.text {
    font-size: 75%;
    color: #aaa;
}

section.bio {
    display: block;
    clear: both;
    overflow: hidden;
    margin: 0 0 1em 0;
}

div.bio-img {
    width: 8em;
    height: 8em;
    float: left;
    margin: 0 1em 1em 0;
    overflow: hidden;
}

div.bio-img img {
    max-width: 100%;
    max-height: 100%;
}

span.bio-date {
    display: block;
    font-style: italic;
    font-size: 75%;
}

span.bio-group {
    display: block;
    font-weight: bold;
}

span.bio-group a {
    font-weight: normal;
    text-decoration: none;
    color: #999;
}

span.bio-group a:hover {
    color: #333;
}

span.bio-desc {
    display: block;
    font-size: 75%;
    line-height: 1.2rem;
}

div.categories-card {
    margin-top: 0 !important;
    align-items: flex-start !important;
}

.categories-card .card-item {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
    padding: 0 !important;
    width: 48% !important;
}

.archive .archive-item {
    margin-left: 0 !important;
}

.archive .archive-item-date {
    min-width: 4em !important;
    width: auto !important;
}

.archive .tag-cloud-tags a {
    margin: 0 10px 10px 0 !important;
}




section.education {
    display: block;
    clear: both;
    overflow: hidden;
    margin: 0 0 1em 0;
}

section.education div.edu-img {
    height: 6em;
    margin-bottom: 1em;
}

section.education div.edu-img img {
    max-width: 100%;
    max-height: 100%;
}

section.education div.university {
    display: block;
    font-size: 140%;
    margin-bottom: 0.5em;
}

section.education div.degree {
}

section.education div.degree div.title {
}

section.education div.degree div.desc,
section.education div.degree div.date {
    font-size: 75%;
    line-height: 1.8;
    padding-left: 2em;
}


figcaption.image-caption {
    padding: 0.5rem 0 0 0.5rem !important;
}

.single .content figure {
    margin: 0;
    padding: 1rem 0;
}